<template>
    <div>custom image</div>
</template>

<script>
  export default {
    name: 'Comp_customImage',
    props: {
      settings: Object
    }
  }
</script>

<style scoped>

</style>
